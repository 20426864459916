<template>
  <div id="login">
    <div class="hero is-medium">
      <div class="hero-body">
        <loading v-if="loading">Signing in...</loading>
        <div class="columns is-multiline is-centered" v-else>
          <div class="column is-12">
            <simsed-logo width="48" font-size="1.5em" />
            <h1 class="has-text-centered">
              School information management application
            </h1>
          </div>
          <div class="column is-6-tablet is-4-desktop">
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <form @submit.prevent="passes(logIn)">
                <template>
                  <section>
                    <SdInput
                      type="text"
                      rules="required|email"
                      label="Email"
                      v-model="email"
                      placeholder="Enter a email"
                    />
                    <SdInput
                      type="password"
                      rules="required"
                      label="Password"
                      v-model="password"
                      password-reveal
                    />
                    <div class="field is-grouped is-grouped-right mt-4">
                      <button type="submit" class="button is-success">
                        Login
                      </button>
                    </div>
                  </section>
                </template>
              </form>
            </ValidationObserver>

            <!-- <div>
              <button
                class="button is-small is-fullwidth mt-5"
                @click="loginWithGoogle"
              >
                Login with Google
              </button>
            </div> -->

            <!-- <div>
              <button
                class="button is-small is-fullwidth mt-5"
                @click="loginWithFacebook"
              >
                Login with Facebook
              </button>
            </div> -->
          </div>
          <div class="column is-12 has-text-centered">
            <router-link to="/reset_password"> Forgot password? </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimsedLogo from '../components/SimsedLogo'
import SdInput from '../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import StoreManager from '../store/store_manager'

export default {
  name: 'login',
  data() {
    return {
      email: null,
      password: null,
    }
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading
    },
  },
  methods: {
    logIn() {
      this.$store.commit('setLoading', true)

      this.$store.dispatch('logIn', {
        email: this.email,
        password: this.password,
      })
    },
    loginWithGoogle() {
      this.$store.dispatch('logInWithGoogle')
    },
    loginWithFacebook() {
      this.$store.dispatch('logInWithFacebook')
    },
  },
  components: {
    SimsedLogo,
    SdInput,
    ValidationObserver,
  },
}
</script>

<style scoped>
#login {
  text-align: left;
}
</style>
